import {
    IconButton,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import React from "react";
import {useTranslation} from "react-i18next";


const GroupTable = ({ isLoading, groups, handleEdit, handleDelete }) => {
    const {t} = useTranslation();
    if (isLoading) {
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Skeleton variant="text" /></TableCell>
                            <TableCell align="right"><Skeleton variant="text" /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...Array(3)].map((_, index) => (
                            <TableRow key={index}>
                                <TableCell><Skeleton variant="text" /></TableCell>
                                <TableCell align="right">
                                    <Skeleton variant="circular" width={40} height={40} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    } else {
        groups.sort((a, b) => a.name.localeCompare(b.name));
    }
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("admin-page.group-table.name-column-title")}</TableCell>
                        <TableCell>{t("admin-page.group-table.color-column-title")}</TableCell>
                        <TableCell align="right">{t("admin-page.group-table.action-column-title")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groups.map((group, index) => (
                        <TableRow key={index}>
                            <TableCell>{group.name}</TableCell>
                            <TableCell
                                style={{
                                    backgroundColor: group.color,
                                    height: "50px",
                                    color: "white",
                                }}
                            >{group.color.toUpperCase()}</TableCell>
                            <TableCell align="right">
                                <IconButton
                                    color="primary"
                                    onClick={() => handleEdit(group.id)}
                                >
                                    <Edit />
                                </IconButton>
                                <IconButton
                                    color="secondary"
                                    onClick={() => handleDelete(group.id)}
                                >
                                    <Delete />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default GroupTable;
