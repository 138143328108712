const translations = {
    "sign-in-page": {
        "sign-in": "Logga in",
        "default-error-message": "Ett fel inträffade vid inloggning. Koden kan endast användas en gång och endast den senaste koden är aktiv.",
        "send-code-default-error-message": "Ett fel inträffade vid skickandet av koden. Vänligen försök igen senare.",
        "send-code-429-error-message": "Du har redan begärt en kod och måste vänta lite innan du kan begära en ny kod.",
    },
    "email-form": {
        "email-label": "Email Adress",
        "submit-button": "Logga in"
    },
    "code-form": {
        "code-label": "Autentiseringskod",
        "try-another-email": "Försök med en annan email",
        "submit-button": "Logga in",
        "code-sent-message": "Vi har skickat en kod till {{email}}",
    },
    "book-form": {
        "title": "Boka {{roomName}}",
        "start-time-label": "Start",
        "end-time-label": "Slut",
        "submit-button": "Boka",
        "cancel-button": "Avbryt"
    },
    "date-picker": {
        "label": "Datum",
        "today": "Idag"
    },
    "room-picker": {
        "label": "Rum"
    },
    "booking-page": {
        "delete-form": {
            "title": "Radera bokning",
            "cancel-button": "Avbryt",
            "delete-button": "Radera"
        },
        "information-dialog": {
            "title": "Bokningsinformation",
            "close-button": "Stäng",
            "delete-button": "Radera"
        }
    },
    "admin-page": {
        "tabs": {
            "user-label": "Användare",
            "group-label": "Grupper",
            "dashboard-label": "Översikt"
        },
        "dashboard": {
            "room-name": "Rum",
            "number-of-bookings": "Antal bokningar",
            "number-of-short-bookings": "1 - 30 min",
            "number-of-medium-bookings": "31 - 90 min",
            "number-of-long-bookings": "> 90 min",
            "start-date-picker": {
                "label": "Från datum"
            },
            "end-date-picker": {
                "label": "Till datum"
            }
        },
        "delete-user-form": {
          "title": "Radera användare",
          "cancel-button": "Avbryt",
          "delete-button": "Radera",
          "error": "Ajdå, något gick fel"
        },
        "delete-group-form": {
            "title": "Radera grupp",
            "cancel-button": "Avbryt",
            "delete-button": "Radera",
            "error": "Ajdå, något gick fel"
        },
        "user-form": {
            "create-title": "Lägg till användare",
            "update-title": "Uppdatera användare",
            "error": "Något gick fel, kontrollera informationen och försök igen.",
            "cancel-button": "Avbryt",
            "name-label": "Namn",
            "email-label": "Epost",
            "role-label": "Roll",
            "group-label": "Grupp",
            "update-button": "Uppdatera",
            "create-button": "Skapa"
        },
        "group-form": {
            "create-title": "Lägg till grupp",
            "update-title": "Uppdatera grupp",
            "error": "Något gick fel, kontrollera informationen och försök igen.",
            "cancel-button": "Avbryt",
            "name-label": "Namn",
            "update-button": "Uppdatera",
            "create-button": "Skapa",
            "color-label": "Färgkod",
        },
        "user-table": {
            "name-column-title": "Namn",
            "email-column-title": "Email",
            "group-column-title": "Grupp",
            "role-column-title": "Roll",
            "action-column-title": "Åtgärder"
        },
        "group-table": {
            "name-column-title": "Namn",
            "color-column-title": "Färg",
            "action-column-title": "Åtgärder"
        },
        "add-user-button": "Lägg till användare",
        "add-group-button": "Lägg till grupp"
    }
}

export default translations;
