import {Container, Box, Tabs, Tab} from '@mui/material';
import AppHeader from "../../components/app-header";
import {getAnalytics, getGroups, getUsers} from "../../services/api";
import useAuth from "../../hooks/use-auth";
import {useQuery} from "@tanstack/react-query";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import UserTab from "./components/users/user-tab";
import GroupTab from "./components/groups/group-tab";
import DashboardTab from "./components/dashboard/dashboard-tab";

const AdminPage = () => {
    const {t} = useTranslation();
    const {token} = useAuth();
    const date = new Date();
    const [ startDate, setStartDate ] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [ endDate, setEndDate ] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    const {isPending: isUsersPending, data: users, refetch: refetchUsers} = useQuery({
        queryKey: ['users'],
        queryFn: async () => {
            return await getUsers(token);
        },
        enabled: !!token
    })
    const {isPending: isGroupsPending, data: groups, refetch: refetchGroups} = useQuery({
        queryKey: ['groups'],
        queryFn: async () => {
            return await getGroups(token);
        },
        enabled: !!token
    });
    const {
        isPending: isAnalyticsPending,
        data: analytics,
    } = useQuery({
        queryKey: ['analytics', startDate, endDate],
        queryFn: async () => {
            return await getAnalytics(token, startDate, endDate);
        },
        enabled: !!token
    })
    const updateStartDate = async (startDate) => {
        setStartDate(startDate);
    }
    const updateEndDate = async(endDate) => {
        setEndDate(endDate);
    }
    const [openTabIndex, setOpenTabIndex] = useState(0);
    const handleTabChange = (event, newValue) => {
        setOpenTabIndex(newValue);
    }
    return (
        <>
            <AppHeader />
            <Container>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={openTabIndex} onChange={handleTabChange}>
                            <Tab label={t("admin-page.tabs.dashboard-label")} />
                            <Tab label={t("admin-page.tabs.user-label")} />
                            <Tab label={t("admin-page.tabs.group-label")} />
                        </Tabs>
                    </Box>
                    <DashboardTab
                        isShown={openTabIndex === 0}
                        isLoading={isAnalyticsPending}
                        analytics={analytics}
                        startDate={startDate}
                        setStartDate={updateStartDate}
                        endDate={endDate}
                        setEndDate={updateEndDate}
                    />
                    <UserTab
                        isShown={openTabIndex === 1}
                        isUsersPending={isUsersPending}
                        users={users}
                        refetchUsers={refetchUsers}
                        isGroupsPending={isGroupsPending}
                        groups={groups}
                    />
                    <GroupTab
                        isShown={openTabIndex === 2}
                        isGroupsPending={isGroupsPending}
                        groups={groups}
                        refetchGroups={refetchGroups}
                    />
                </Box>
            </Container>
        </>
    );
}

export default AdminPage;
