import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {sv} from "date-fns/locale";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";


const DashboardTab = ({
    isShown,
    isLoading,
    analytics,
    startDate,
    setStartDate,
    endDate,
    setEndDate
}) => {
    const { t } = useTranslation();

    if (!isShown) return null;

    if (isLoading) {
        return (
            <div>
                Loading...
            </div>
        )
    }

    return (
        <div
            hidden={!isShown}
        >
            <Box component={Paper} mb={3} mt={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
                    <Grid container spacing={2} padding={2}>
                        <Grid>
                            <DatePicker
                                label={t("admin-page.dashboard.start-date-picker.label")}
                                value={startDate}
                                onChange={(newDate) => setStartDate(newDate)}
                            />
                        </Grid>
                        <Grid>
                            <DatePicker
                                label={t("admin-page.dashboard.end-date-picker.label")}
                                value={endDate}
                                onChange={(newDate) => setEndDate(newDate)}
                            />
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="bookings per room table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>
                                    {t("admin-page.dashboard.room-name")}
                                </strong>
                            </TableCell>
                            <TableCell align="center">
                                <strong>
                                    {t("admin-page.dashboard.number-of-short-bookings")}
                                </strong>
                            </TableCell>
                            <TableCell align="center">
                                <strong>
                                    {t("admin-page.dashboard.number-of-medium-bookings")}
                                </strong>
                            </TableCell>
                            <TableCell align="center">
                                <strong>
                                    {t("admin-page.dashboard.number-of-long-bookings")}
                                </strong>
                            </TableCell>
                            <TableCell align="center">
                                <strong>
                                    {t("admin-page.dashboard.number-of-bookings")}
                                </strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.entries(analytics.bookingsPerRoom).map(([roomName, bookings], idx) => {
                                return (
                                    <TableRow key={idx}>
                                        <TableCell component="th" scope="row">
                                            {roomName}
                                        </TableCell>
                                        <TableCell align="center">{bookings.short}</TableCell>
                                        <TableCell align="center">{bookings.medium}</TableCell>
                                        <TableCell align="center">{bookings.long}</TableCell>
                                        <TableCell align="center">{bookings.total}</TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default DashboardTab;
