import axios from 'axios';
import {BASE_URL} from "../constants";
import {formatISO} from "date-fns";

export const sendAuthCode = async (email) => {
    return await axios.get(`${BASE_URL}/authentication/code/${email}`);
}

export const getJwt = async (email, code) => {
    return await axios.post(`${BASE_URL}/authentication`, { email, code });
}

export const getRooms = async (jwt) => {
    return await axios.get(
        `${BASE_URL}/rooms`,
        {
            headers: { Authorization: `Bearer ${jwt}` }
        }
    );
}

export const getBookings = async (jwt, start_date, end_date) => {
    return await axios.get(
        `${BASE_URL}/bookings`,
        {
            headers: { Authorization: `Bearer ${jwt}` },
            params: {
                start_time: formatISO(start_date),
                end_time: formatISO(end_date)
            }
        }
    );
}

export const createBooking = async (jwt, room_id, start_time, end_time) => {
    return await axios.post(
        `${BASE_URL}/bookings`,
        {
            room_id,
            start_time: formatISO(start_time),
            end_time: formatISO(end_time)
        },
        {
            headers: { Authorization: `Bearer ${jwt}` }
        }
    )
}

export const deleteBooking = async (jwt, booking_id) => {
    return await axios.delete(
        `${BASE_URL}/bookings/${booking_id}`,
        {
            headers: { Authorization: `Bearer ${jwt}` }
        }
    )
}


export const getUsers = async (jwt) => {
    const response = await axios.get(
        `${BASE_URL}/users`,
        {
            headers: { Authorization: `Bearer ${jwt}` }
        }
    );
    return response.data.map(user => ({
        id: user.id,
        name: user.name,
        email: user.email,
        role: user.role,
        groupId: user.group_id,
        groupName: user.group_name
    }));
}

export const getGroups = async (jwt) => {
    const response = await axios.get(
        `${BASE_URL}/groups`,
        {
            headers: { Authorization: `Bearer ${jwt}` }
        }
    );
    return response.data.map(group => ({ id: group.group_id, name: group.name, color: group.color }));
}


export const createUser = async (jwt, name, email, role, group_id) => {
    return await axios.post(
        `${BASE_URL}/users`,
        {
            name,
            email,
            role,
            group_id
        },
        {
            headers: { Authorization: `Bearer ${jwt}` }
        }
    );
}

export const updateUser = async (jwt, id, name, email, role, group_id) => {
    return await axios.put(
        `${BASE_URL}/users/${id}`,
        {
            name,
            email,
            role,
            group_id
        },
        {
            headers: { Authorization: `Bearer ${jwt}` }
        }
    );
}


export const deleteUser = async (jwt, id) => {
    return await axios.delete(
        `${BASE_URL}/users/${id}`,
        {
            headers: { Authorization: `Bearer ${jwt}` }
        }
    );
}

export const createGroup = async (jwt, name, color) => {
    return await axios.post(
        `${BASE_URL}/groups`,
        { name, color },
        {
            headers: { Authorization: `Bearer ${jwt}` }
        }
    );
}

export const deleteGroup = async (jwt, id) => {
    return await axios.delete(
        `${BASE_URL}/groups/${id}`,
        {
            headers: { Authorization: `Bearer ${jwt}` }
        }
    );
}

export const updateGroup = async (jwt, id, name, color) => {
    return await axios.put(
        `${BASE_URL}/groups/${id}`,
        { name, color },
        {
            headers: { Authorization: `Bearer ${jwt}` }
        }
    );
}

export const getAnalytics = async (jwt, startDate, endDate) => {
    const response =  await axios.get(
        `${BASE_URL}/analytics`,
        {
            headers: { Authorization: `Bearer ${jwt}` },
            params: {
                start_time: formatISO(startDate),
                end_time: formatISO(endDate)
            }
        }
    );
    return {
        bookingsPerRoom: response.data.bookings_per_room
    }
}
