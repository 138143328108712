import React, { useState } from 'react';
import {
    TextField,
    Button,
    Container,
    Box,
    Skeleton,
    Typography
} from '@mui/material';
import {useTranslation} from "react-i18next";
import { HexColorPicker } from "react-colorful";

const GroupForm = ({ isLoading, onSubmit, group, error }) => {
    const {t} = useTranslation();
    const [name, setName] = useState(group?.name || '');
    const [color, setColor] = useState(group?.color || '#45b54e');
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ id: group?.id || null, name, color});
    };


    if (isLoading) {
        return (
            <Container maxWidth="sm">
                <Box sx={{ mt: 3 }}>
                    <Skeleton variant="rectangular" width="100%" height={56} sx={{ mb: 2 }} />
                    <Skeleton variant="rectangular" width="100%" height={36} />
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="sm">
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
                <TextField
                    fullWidth
                    label={t("admin-page.group-form.name-label")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    margin="normal"
                    required
                />
                <HexColorPicker color={color} onChange={setColor}/>
                <TextField
                    mt={1}
                    label={t("admin-page.group-form.color-label")}
                    value={color.toUpperCase()}
                    onChange={(e) => setColor(e.target.value)}
                    margin="normal"
                    inputProps={{ maxLength: 7 }}
                />
                <Typography variant="body1" color="error">{error}</Typography>
                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 3 }}>
                    {group ? t("admin-page.group-form.update-button") : t("admin-page.group-form.create-button")}
                </Button>
            </Box>
        </Container>
    );
};

export default GroupForm;
