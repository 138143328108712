import {Button, Dialog, DialogTitle, DialogContent, Box, DialogActions} from '@mui/material';
import {createGroup, deleteGroup, updateGroup} from "../../../../services/api";
import useAuth from "../../../../hooks/use-auth";
import GroupTable from "./group-table";
import GroupForm from "./group-form";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import GroupDeleteForm from "./group-delete-form";

const GroupTab = ({isShown, isGroupsPending, groups, refetchGroups}) => {
    const {t} = useTranslation();
    const {token} = useAuth();
    const [isGroupFormOpen, setIsGroupFormOpen] = useState(false);
    const [groupBeingEdited, setGroupBeingEdited] = useState(null);
    const [groupError, setGroupError] = useState("");
    const [isGroupDeleteFormOpen, setIsGroupDeleteFormOpen] = useState(false);

    const handleDelete = (id) => {
        setGroupBeingEdited(groups.find(group => group.id === id));
        setIsGroupDeleteFormOpen(true);
    };

    const handleEdit = (id) => {
        setGroupBeingEdited(groups.find(group => group.id === id));
        setIsGroupFormOpen(true);
    };

    const handleAddGroup = () => {
        setGroupBeingEdited(null);
        setIsGroupFormOpen(true);
    };

    const handleGroupFormSubmit = async (data) => {
        try {
            if (data.id) {
                await updateGroup(token, data.id, data.name, data.color);
            } else {

                await createGroup(token, data.name, data.color);
            }
        } catch (e) {
            console.error(e)
            setGroupError(t("admin-page.group-form.error"));
            return;
        }
        setIsGroupFormOpen(false);
        await refetchGroups();
    }

    const handleGroupDeleteSubmit = async (id) => {
        try {
            await deleteGroup(token, id);
        } catch (e) {
            console.error(e)
            setGroupError(t("admin-page.delete-group-form.error"))
            return;
        }
        setIsGroupDeleteFormOpen(false);
        await refetchGroups();
    }

    const handleCloseGroupForm = () => {
        setIsGroupFormOpen(false);
        setGroupBeingEdited(null);
    }

    const handleCloseGroupDeleteForm = () => {
        setIsGroupDeleteFormOpen(false);
        setGroupBeingEdited(null);
    }

    if (!isShown) return null;

    return (
        <div
            hidden={!isShown}
        >
            <Button
                variant="contained"
                color="primary"
                onClick={handleAddGroup}
                style={{ marginBottom: '20px', marginTop: '20px' }}
            >
                {t("admin-page.add-group-button")}
            </Button>
            <GroupTable
                isLoading={isGroupsPending}
                groups={groups || []}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
            <Dialog open={isGroupFormOpen} onClose={handleCloseGroupForm} maxWidth="sm" fullWidth>
                <DialogTitle>{groupBeingEdited ? t("admin-page.group-form.update-title") : t("admin-page.group-form.create-title")}</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <GroupForm
                            error={groupError}
                            isLoading={isGroupsPending}
                            group={groupBeingEdited}
                            groups={groups}
                            onSubmit={handleGroupFormSubmit}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseGroupForm} color="secondary">
                        {t("admin-page.group-form.cancel-button")}
                    </Button>
                </DialogActions>
            </Dialog>
            <GroupDeleteForm
                group={groupBeingEdited}
                onClose={handleCloseGroupDeleteForm}
                onDelete={handleGroupDeleteSubmit}
                isOpen={isGroupDeleteFormOpen}
            />
        </div>
    );
}

export default GroupTab;
